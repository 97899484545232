import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	ViewChild,
	effect,
	inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatInputFieldComponent } from '@app/ai-chat/chat-conversation-area/chat-input-field/chat-input-field.component';
import { ChatBuildingSelectionComponent } from '@app/ai-chat/chat-building-selection/chat-building-selection.component';
import { AiChatService } from '@app/ai-chat/services/ai-chat.service';
import { UserTypedMessageComponent } from '@app/ai-chat/chat-conversation-area/user-typed-message/user-typed-message.component';
import { AiResponseMessageComponent } from '@app/ai-chat/chat-conversation-area/ai-response-message/ai-response-message.component';
import { Store } from '@ngrx/store';
import { getCurrentUser } from '@auth/state/auth.selectors';

@Component({
	selector: 'chat-conversation-area',
	imports: [
		CommonModule,
		ChatInputFieldComponent,
		ChatBuildingSelectionComponent,
		UserTypedMessageComponent,
		AiResponseMessageComponent,
	],
	templateUrl: './chat-conversation-area.component.html',
	styleUrls: ['./chat-conversation-area.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatConversationAreaComponent {
	@ViewChild('chatWrapper') private chatWrapper?: ElementRef<HTMLDivElement>;

	private readonly aiChatService = inject(AiChatService);
	private readonly store$ = inject(Store);

	profile = this.store$.selectSignal(getCurrentUser);
	chatMessages = this.aiChatService.aiChatMessages;
	selectedChatId = this.aiChatService.selectedChatId;
	isLoadingReply = this.aiChatService.isLoadingReply;

	constructor() {
		// Create an effect to watch for changes in messages and trigger scroll
		effect(() => {
			const messages = this.chatMessages();

			if (messages?.length) {
				// Wait for DOM update and then scroll
				setTimeout(() => this.scrollToBottom(), 150);
			}
		});
	}

	private scrollToBottom(): void {
		if (!this.chatWrapper) return;

		const element = this.chatWrapper.nativeElement;
		const scrollOptions: ScrollToOptions = {
			top: element.scrollHeight,
			behavior: 'smooth',
		};

		element.scrollTo(scrollOptions);
	}
}
