import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AiChatService } from '@app/ai-chat/services/ai-chat.service';
import { MatRippleModule } from '@angular/material/core';
import { NgxTolgeeModule } from '@tolgee/ngx';

@Component({
	selector: 'chat-list-header',
	imports: [
		CommonModule,
		MatIconModule,
		MatTooltipModule,
		MatRippleModule,
		NgxTolgeeModule,
	],
	templateUrl: './chat-list-header.component.html',
	styleUrls: ['./chat-list-header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatListHeaderComponent {
	private readonly aiChatService = inject(AiChatService);

	createNewChat() {
		this.aiChatService.createNewChat();
	}
}
