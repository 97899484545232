import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	ElementRef,
	inject,
	Input,
	OnInit,
	ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { AiChatService } from '@app/ai-chat/services/ai-chat.service';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { noWhitespaceValidator } from '@shared/validators/no-white-spaces.validator';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgxTolgeeModule } from '@tolgee/ngx';

@Component({
	selector: 'chat-input-field',
	imports: [CommonModule, MatIconModule, ReactiveFormsModule, NgxTolgeeModule],
	templateUrl: './chat-input-field.component.html',
	styleUrls: ['./chat-input-field.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatInputFieldComponent implements OnInit {
	private readonly aiChatService = inject(AiChatService);
	private readonly destroyRef = inject(DestroyRef);

	@ViewChild('messageInput') textArea: ElementRef<HTMLTextAreaElement>;

	@Input() maxRows = 4;
	@Input() lineHeight = 26;

	formControl = new FormControl<string>('', [
		Validators.required,
		noWhitespaceValidator(),
	]);

	isLoadingReply = this.aiChatService.isLoadingReply;

	ngOnInit() {
		this.aiChatService.activeChatChanged$
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => this.clearInputAndRestoreHeight());
	}

	adjustRows(textArea: HTMLTextAreaElement) {
		textArea.style.height = 'auto'; // Temporarily shrink to minimum size to get scrollHeight
		const rows = Math.min(this.maxRows, textArea.scrollHeight / this.lineHeight); // replace `line-height` with yours
		textArea.style.height = `${rows * this.lineHeight}px`; // Adjust to correct scrollHeight, or 4 lines
	}

	submitRequestToAiChat() {
		if (this.formControl.valid && !this.aiChatService.isLoadingReply()) {
			this.aiChatService.submitRequest(this.formattedValue());
			this.clearInputAndRestoreHeight();
		}
	}

	trackHotKeys($event: KeyboardEvent) {
		if ($event.key === 'Enter' && !$event.shiftKey) {
			$event.preventDefault();
			this.submitRequestToAiChat();
		}
	}

	private clearInputAndRestoreHeight() {
		this.formControl.reset();
		this.textArea.nativeElement.style.height = `${this.lineHeight}px`;
	}

	private formattedValue(): string {
		const value = this.formControl.value.trim();
		// regular expression that matches newline characters (\r or \n) at the start (^) or end ($) of a string.
		return value.replace(/^[\r\n]+|[\r\n]+$/g, '');
	}
}
