import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, Inject, isDevMode, NgModule } from '@angular/core';
import config from '@app/configs/au-main-config';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';

import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { provideStoreDevtools, StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
	FullRouterStateSerializer,
	StoreRouterConnectingModule,
} from '@ngrx/router-store';
import {
	DevTools,
	NgxTolgeeModule,
	Tolgee,
	TOLGEE_INSTANCE,
	LanguageStorage,
} from '@tolgee/ngx';

import { FlexLayoutModule } from '@angular/flex-layout';
import { AuthModule } from '@auth/auth.module';
import { provideStore, StoreModule } from '@ngrx/store';
import { metaReducers, reducers } from './configs/reducers.config';
import { environment } from '@env/environment';
import { EffectsModule, provideEffects } from '@ngrx/effects';
import { AppRoutingModule } from './app-routing.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { ReactiveFormsModule } from '@angular/forms';
import { ApplicationLayoutModule } from '@layout/application-layout.module';
import { GraphQLModule } from './graphql.module';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { provideNgxMask } from 'ngx-mask';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NavigationEnd, Router } from '@angular/router';
import { APP_BASE_HREF, DatePipe, DOCUMENT } from '@angular/common';
import { filter } from 'rxjs/operators';
import { KeycloakAngularModule } from 'keycloak-angular';
import { getAppProviders } from '@app/authentication.setup';
import { CoreModule } from '@core/core.module';
import { NgxPendoModule } from 'ngx-pendo';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { AuMatCustomDateAdapter } from '@app/pages/cmms/components/cmms-sort-filters-grouping/cmms-applied-filters-bar/date-range-filter/au-mat-custom-date-adapter';
import { IntercomModule } from '@supy-io/ngx-intercom';
import * as Sentry from '@sentry/angular';
import { TeamNameResolver } from '@app/pages/settings/users/resolvers/team-name.resolver';
import { AssetBuildingNameResolver } from '@app/pages/settings/portfolio/resolvers/asset-building-name.resolver';
import { OrganizationNameResolver } from '@app/pages/settings/users/resolvers/organization-name.resolver';
import { UserNameResolver } from '@app/pages/settings/users/resolvers/user-name.resolver';
import { AssetZoneNameResolver } from '@app/pages/settings/portfolio/resolvers/asset-zone-name.resolver';
import { AuEditUserService } from '@app/pages/settings/users/pages/edit-user/edit-user-service';
import { NgxSmartBannerModule } from '@netcreaties/ngx-smart-banner';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { FilesEffects } from './pages/cmms/components/files/state/files.effects';
import { CmmsFeatureKey, cmmsReducers } from './pages/cmms/state/cmms.reducer';
import { EmptyPlaceholderComponent } from './shared/ui-components/empty-placeholder/empty-placeholder.component';

@NgModule({
	declarations: [AppComponent],
	bootstrap: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		NgxPendoModule.forRoot({
			pendoApiKey: config.portal.pendoApiKey,
			pendoIdFormatter: (value: any) => value.toString().toLowerCase(),
		}),
		MatMenuModule,
		MatIconModule,
		MatSidenavModule,
		MatListModule,
		MatToolbarModule,
		MatBottomSheetModule,
		FlexLayoutModule,
		ReactiveFormsModule,
		OverlayModule,
		AuthModule,
		ApplicationLayoutModule,
		AppRoutingModule,
		GraphQLModule,
		NgxTolgeeModule,
		StoreModule.forRoot(reducers, {
			metaReducers,
		}),
		StoreModule.forFeature(CmmsFeatureKey, cmmsReducers),
		!environment.production
			? StoreDevtoolsModule.instrument({ connectInZone: true })
			: [],
		EffectsModule.forRoot([FilesEffects]),
		StoreRouterConnectingModule.forRoot({
			serializer: FullRouterStateSerializer,
			stateKey: 'router',
		}),
		EmptyPlaceholderComponent,
		MatSnackBarModule,
		MatDialogModule,
		MatAutocompleteModule,
		KeycloakAngularModule,
		CoreModule,
		MatNativeDateModule,
		IntercomModule.forRoot({
			appId: config.portal.intercomId,
			updateOnRouterChange: true,
		}),
		NgxSmartBannerModule,
	],
	providers: [
		/**
		 * Currently we support authentication through keycloak and autility
		 * login structure. You can select authentication way in environment configuration
		 */
		getAppProviders(),
		provideNgxMask(),
		DatePipe,
		{ provide: DateAdapter, useClass: AuMatCustomDateAdapter },
		{ provide: APP_BASE_HREF, useValue: environment.baseHref },
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler({
				showDialog: false,
			}),
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
		TeamNameResolver,
		AssetBuildingNameResolver,
		OrganizationNameResolver,
		UserNameResolver,
		AssetZoneNameResolver,
		AuEditUserService,
		provideStore(reducers, { metaReducers }),
		provideStoreDevtools({
			maxAge: 25, // Retains last 25 states
			logOnly: !isDevMode(), // Restrict extension to log-only mode
			autoPause: true, // Pauses recording actions and state changes when the extension window is not open
			trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
			traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
			connectInZone: true,
		}),
		provideEffects(),
		provideHttpClient(withInterceptorsFromDi()),
		{
			provide: TOLGEE_INSTANCE,
			useFactory: () => {
				return (
					Tolgee()
						.use(DevTools())
						.use(LanguageStorage())
						// .use(LanguageDetector()) Disabled until language translation is ready to be deployed (not experimental)

						.init({
							defaultLanguage: 'en',
							availableLanguages: ['en', 'nb'],

							staticData: {
								en: async () => import('../assets/i18n/en.json'),
								nb: async () => import('../assets/i18n/nb.json'),
							},
						})
				);
			},
		},
	],
})
export class AppModule {
	constructor(
		router: Router,
		@Inject(DOCUMENT) private document: Document,
		_trace: Sentry.TraceService
	) {
		/**
		 * Notice. This code scroll page to top on every NavigationEnd event but also
		 * brake angular scroll position restoration logic when user goes to previous page.
		 * So, if you need to restore this logic use another way
		 */
		router.events
			.pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
			.subscribe(() => {
				// needs null safe operator
				// because there is no #scroll-to-top-anchor on login page
				this.document.querySelector('#scroll-to-top-anchor')?.scrollIntoView();
			});
	}
}
