import { EnvironmentNames } from '@app/shared';

export interface IAuNavbarConfig {
	title: string; // Title should be unique
	id?: string;
	svgIcon?: string;
	/**
	 * If you don't provide routerLink in child it will use
	 * parent one and will not highlight it as active
	 */
	routerLink?: string;
	className?: string;
	hideForEnvironment?: [EnvironmentNames];
	children?: IAuNavbarConfig[];
	expanded?: boolean;
	/**
	 * Use onClickAction with caution as if you have router link
	 * it will do both, navigate and run this action. It is nearly impossible
	 * to do either clickAction and programmatic navigation or
	 * routerLink directive navigation. You'll face problem that it will not
	 * highlight active link if you've try to remove the directives and
	 * do it programmatically.
	 */
	onClickAction?: () => void;
	action?: INavbarActionConfig;
	page?: boolean;
	showCounter?: boolean;
}

export interface INavbarActionConfig {
	svgIcon: string;
	methodKey: string;
	className?: string;
	isProvidedMenuAction?: boolean;
}

// Important that the title is the key in Tolgee, not the actual title
// Otherwise the translation will not work
export const auNavbarConfig = () => {
	return [
		{
			title: 'dashboard',
			svgIcon: 'home',
			routerLink: 'dashboard',
			className: 'dashboard',
		},
		{
			title: 'inbox_header',
			svgIcon: 'bell',
			routerLink: 'inbox',
			className: 'inbox',
			showCounter: true,
		},
		{
			title: 'operations',
			svgIcon: 'cmms',
			routerLink: 'cmms',
			className: 'cmms folder',
			expanded: true,
			page: false,
			children: [
				{
					title: 'work_orders',
					routerLink: 'work-orders',
				},
				{
					title: 'incidents',
					routerLink: 'incidents',
				},
				{
					title: 'requests_header',
					routerLink: 'requests',
				},
				{
					title: 'files',
					routerLink: 'files',
				},
				{
					title: 'assets',
					routerLink: 'assets',
				},
				{
					title: 'messages',
					routerLink: 'messages',
					className: 'cmms',
				},
				{
					title: 'contacts',
					routerLink: 'contacts',
					className: 'cmms',
				},
			],
		},
		{
			title: 'map',
			svgIcon: 'location-pin',
			routerLink: 'digital-twin',
			className: 'digital-twin',
		},
	] as IAuNavbarConfig[];
};
//This is a specific route that will replace main navigation on settings route
export const settingsNavbarConfig = () => {
	return [
		{
			title: 'organization',
			svgIcon: 'organizationV2',
			routerLink: 'settings/organization',
			className: 'organization-settings',
			page: false,
			expanded: true,
			children: [
				{
					title: 'about',
					routerLink: 'about',
				},
				{
					title: 'buildings',
					routerLink: 'portfolio',
					className: 'portfolio',
				},
				{
					title: 'labels',
					routerLink: 'labels',
				},
				{
					title: 'import',
					routerLink: 'import',
					expanded: true,
					page: false,
					children: [
						{
							title: 'files',
							routerLink: 'files',
							className: 'files',
						},
					],
				},
				{
					title: 'user_management',
					routerLink: 'users',
					className: 'users',
				},
				{
					title: 'email_service',
					routerLink: 'email-service',
				},
			],
		},
		{
			title: 'my_settings',
			svgIcon: 'user',
			routerLink: 'settings/profile',
			className: 'user-settings',
			expanded: true,
			page: false,
			children: [
				{
					title: 'edit_profile',
					routerLink: 'edit',
				},
				{
					title: 'experiments',
					routerLink: 'experiments',
				},
				{
					title: 'notifications',
					routerLink: 'notifications',
				},
			],
		},
	] as IAuNavbarConfig[];
};
