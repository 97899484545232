<div class="available-buildings nice-scroll visible-scroll colored-scroll-track">
	<au-search-input
		class="available-buildings-search-input"
		placeholder="{{ 'enter_building_name' | translate }}..."
		subscriptSizingMode="dynamic"
		[value]="searchText()"
		(click)="$event.stopPropagation()"
		(searchTextChanged)="searchText.set($event)"
	/>
	@if (!loading()) {
		@for (
			building of notFollowedBuildings() | auQuickSearchName: searchText();
			track building.id
		) {
			<div class="building-item" (click)="followBuilding(building)">
				<p>{{ building.name }}</p>
			</div>
		}
		@if (!notFollowedBuildings().length) {
			<div class="building-item">
				<p class="body-2 grey">No buildings to follow</p>
			</div>
		}
	} @else {
		<div class="loading-spinner flex-center-center">
			<mat-spinner diameter="30" />
		</div>
	}
</div>
