import gql from 'graphql-tag';
import { PageInfoFragment } from '@app/pages/cmms/graphql/cmms-fragments';

export const NotificationFragment = gql`
	fragment NotificationFragment on NotificationType {
		created
		createdBy {
			contentType
			firstName
			lastName
			name
			psId
			smallAvatarUrl
			smallLogoUrl
		}
		event
		footer
		id
		isRead
		object {
			pk
			type
		}
		pk
		text
		title
		locations {
			locationId
			name
			locationType
		}
	}
`;

export const NotificationsQuery = gql`
	query NotificationsQuery(
		$first: Int
		$after: String
		$before: String
		$last: Int
		$offset: Int
		$search: String
	) {
		notifications(
			first: $first
			after: $after
			before: $before
			last: $last
			offset: $offset
			search: $search
		) {
			pageInfo {
				...PageInfoFragment
			}
			edges {
				cursor
				node {
					...NotificationFragment
				}
			}
			totalCount
			hasUnread
		}
	}
	${PageInfoFragment}
	${NotificationFragment}
`;

export const unreadNotificationsQuery = gql`
	query unreadNotificationsQuery {
		unreadNotifications
	}
`;
