<p class="chat-title">{{ 'chats' | translate }}</p>
<button
	class="au-add-fab-button"
	[matTooltip]="'start_new_chat' | translate"
	(click)="createNewChat()"
	matRipple
	matRippleColor="rgba(#1c4b31, 0.05)"
>
	<mat-icon class="accent-icon" svgIcon="add">add</mat-icon>
</button>
